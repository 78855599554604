@import '@drdropin-tech/theseus/lib/base.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'tailwindcss/variants';

@import '@drdropin-tech/theseus/lib/overrides.css';

html,
body,
#__next {
  @apply h-full;
}

html {
  overflow-x: hidden;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.btn.rotate-icon-135 span {
  @apply rotate-135;
}

/* override for fixed width icon buttons */
.btn-sm.btn-circle.btn-wide,
.btn-md.btn-circle.btn-wide {
  width: auto;
  @apply px-6;
}

@media screen(lg) {
  .reset-sidebar {
    z-index: unset !important;
    transform: none !important;
  }
}

html {
  font-size: 16px;
}

/* Changes DaisyUI's sidebar static/overlay treshold */
/* Todo: change to treshold to sm */

@media (min-width: theme(screens.md)) {
  .drawer-mobile-small {
    grid-auto-columns: max-content auto;
    & > .drawer-toggle {
      & ~ .drawer-content {
        @apply h-auto md:col-start-2;
      }
      & ~ .drawer-side {
        @apply overflow-y-auto;
        & > .drawer-overlay {
          @apply md:visible;
        }
        & > .drawer-overlay + * {
          @apply md:translate-x-0;
        }
      }
    }
  }
}
